<!--
 * @Author: Liu Hui
 * @Description: 客户标准销售价
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="occupy-price-strategy-detail-content">
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header" class="clearfix">
          <span>配置基础信息</span>
        </div>
        <YkcDetailItemPlus label="电站名称">
          {{ dealData(detail.stationName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="归属商户">
          {{ dealData(detail.stationOperatorName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="是否收取">
          {{ { 0: '否', 1: '是' }[detail.collectFlag] || '一' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="减免时长">
          {{ dealData(detail.deductionTime) }}分钟
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="占位费用">
          {{ dealData(detail.occupyAmount) }}元/分钟
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="收取时间">
          {{ parseTime(detail.startTime) }} ~ {{ parseTime(detail.endTime) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="费用上限">
          {{ dealData(detail.occupyAmountLimit) }}元
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="修改人">
          {{ dealData(detail.modifiedAccount) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="修改时间">
          <div class="ellipsis">
            {{ dealData(detail.modifiedTime) }}
          </div>
        </YkcDetailItemPlus>
      </YkcDetailPlus>
    </div>
    <div>
      <ykc-table
        :data="tableData"
        :columns="tableColumns"
        title="客户列表"
        :headerButtons="tableHeaderButtons.filter(item => item.enabled())">
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { dealData, offlineExport } from '@/utils/index';
  import { occupyStrategy } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'SellingPriceDetail',
    data(vm) {
      return {
        detail: {},
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        tableData: [],
        tableHeaderButtons: [
          {
            enabled: () => code('operation:price:place:detailexport'),
            render() {
              return <ykc-button onClick={vm.exportData}>导出</ykc-button>;
            },
          },
        ],
        tableColumns: [
          {
            label: '客户编码',
            prop: 'orgId',
          },
          {
            label: '客户名称',
            prop: 'orgName',
          },
          {
            label: '客户类型',
            prop: 'orgType',
          },
        ],
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    created() {
      this.getDetail();
      this.getTableList();
    },
    methods: {
      dealData,
      exportData() {
        const { stationId } = this.$route.query;
        offlineExport(
          {
            downloadType: 'station_price_export',
            jsonNode: {
              downloadKey: 'occupy_price_org_list',
              stationId,
              size: this.pageInfo.pageSize,
              current: this.pageInfo.pageIndex,
            },
          },
          this.pageInfo.total
        );
      },
      parseTime(id) {
        id = Number(id);
        const index = id - 1;
        const hour = Math.floor(index / 2);
        const minute = (index % 2) * 30;
        const name = `${`${hour}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}`;
        return name;
      },
      /**
       * 获取详情数据
       */
      async getDetail() {
        const { stationId } = this.$route.query;
        const response = await occupyStrategy.detail({ stationId });
        console.log('getDetail response', response);
        this.detail = response;
      },
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.getTableList();
      },
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.getTableList();
      },
      getTableList() {
        const { stationId } = this.$route.query;
        const data = {
          stationId,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        occupyStrategy.queryOrgForPage(data).then(res => {
          this.tableData = res.records;
          this.pageInfo.total = res.total;
          this.pageInfo.pageIndex = res.current;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .occupy-price-strategy-detail-content {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
    .box-card {
      .title {
        color: inherit;
      }
      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        th {
          height: 44px;
        }
        td {
          height: 40px;
        }
        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
</style>
